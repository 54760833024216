import {
  Create,
  Datagrid,
  DatagridHeader,
  Edit,
  EditButton,
  List,
  SimpleForm,
  DateField,
  FunctionField,
  TextInput,
  SaveButton,
  Toolbar,
  TopToolbar,
  CreateButton,
  required,
  regex,
  BooleanField,
  TextField,
  BooleanInput,
} from "react-admin";
import { Stack, Typography, Grid, Button } from "@mui/material";
export const SysAccountList = (props: any) => (
  <List {...props} title="Amidex　発注システム　管理アカウント情報">
    <Datagrid header={DatagridHeader}>
      <TextField source="email" label="email" />
      <BooleanField source="is_active" label="利用可能" />
      <EditButton label="編集" />
    </Datagrid>
  </List>
);

export const SysAccountEdit = (props: any) => (
  <Edit {...props} title="管理アカウント情報(明細)">
    <SimpleForm toolbar={<CustomToolbar />}>
      <SysAccountEditBase saveButtonLabel="管理アカウント情報編集" />
      <Grid container spacing={2}>
        <Grid item xs={2} mt={1}>
          <Typography variant="subtitle1">利用可能</Typography>
        </Grid>
        <Grid item xs={10}>
          <BooleanInput label={false} source="is_active" />
        </Grid>
      </Grid>
    </SimpleForm>
  </Edit>
);

export const SysAccountCreate = (props: any) => (
  <Create {...props}>
    <SimpleForm>
      <SysAccountEditBase
        disabled={false}
        saveButtonLabel="管理アカウント情報登録"
        isCreate
      />
      <Grid container spacing={2}>
        <Grid item xs={2} mt={2}>
          <Typography variant="subtitle1">パスワード</Typography>
        </Grid>
        <Grid item xs={10}>
          <TextInput
            source="password"
            label="パスワード"
            sx={{ width: "400px" }}
            validate={validatePassword}
          />
        </Grid>
      </Grid>
    </SimpleForm>
  </Create>
);

export const SysAccountEditBase = ({ disabled = true, ...props }) => (
  <>
    <Typography variant="h6" fontWeight="bold" gutterBottom>
      管理アカウント情報<span>{props.isCreate ? " 新規登録" : ""}</span>
    </Typography>
    <Grid container spacing={2}>
      <Grid item xs={2} mt={2}>
        <Typography variant="subtitle1">メールアドレス</Typography>
      </Grid>
      <Grid item xs={10}>
        <TextInput
          source="email"
          label="Email"
          sx={{ width: "400px" }}
          validate={validateEmail}
          disabled={disabled}
        />
      </Grid>
    </Grid>
  </>
);

// 削除ボタンを消したツールバーを定義
const CustomToolbar = () => (
  <Toolbar>
    <SaveButton label="保存" />
  </Toolbar>
);

// バリデーション
const validateEmail = [required("メールアドレスを入力してください")];
const validatePassword = [required("パスワードを入力してください")];
