import {
  Create,
  Datagrid,
  DatagridHeader,
  Edit,
  EditButton,
  List,
  SimpleForm,
  DateField,
  FunctionField,
  TextInput,
  SaveButton,
  Toolbar,
  TopToolbar,
  CreateButton,
  required,
  regex,
} from "react-admin";
import { Stack, Typography, Grid, Button } from "@mui/material";
export const DoctorList = (props: any) => (
  <List
    {...props}
    filters={[]}
    actions={<DoctorActions />}
    title="Amidex　発注システム　医院情報"
  >
    <Datagrid header={DatagridHeader}>
      <FunctionField
        source="last_kana"
        render={(record: any) => `${record.last_name} ${record.first_name}`}
        label="医師名"
      />
      <DateField source="created" label="登録日" />
      <EditButton label="編集" />
    </Datagrid>
  </List>
);

export const DoctorEdit = (props: any) => (
  <Edit {...props} title="医師情報(明細)">
    <DoctorEditBase saveButtonLabel="医師情報編集" />
  </Edit>
);

export const DoctorCreate = (props: any) => (
  <Create {...props}>
    <DoctorEditBase saveButtonLabel="医師情報登録" isCreate />
  </Create>
);

export const DoctorEditBase = ({ disabled = true, ...props }) => (
  <SimpleForm
    toolbar={<DoctorEditToolbar saveButtonLabel={props.saveButtonLabel} />}
  >
    <Typography variant="h6" fontWeight="bold" gutterBottom>
      医師情報<span>{props.isCreate ? " 新規登録" : ""}</span>
    </Typography>
    <Grid container spacing={2}>
      <Grid item xs={2} mt={2}>
        <Typography variant="subtitle1">医師名（漢字）</Typography>
      </Grid>
      <Grid item xs="auto">
        <TextInput source="last_name" label="姓" validate={validateLastName} />
      </Grid>
      <Grid item xs="auto">
        <TextInput
          source="first_name"
          label="名"
          validate={validateFirstName}
        />
      </Grid>
    </Grid>
    <Grid container spacing={2}>
      <Grid item xs={2} mt={2}>
        <Typography variant="subtitle1">医師名（カナ）</Typography>
      </Grid>
      <Grid item xs="auto">
        <TextInput
          source="last_kana"
          label="セイ"
          validate={validateLastKana}
        />
      </Grid>
      <Grid item xs="auto">
        <TextInput
          source="first_kana"
          label="メイ"
          validate={validateFirstKana}
        />
      </Grid>
    </Grid>
  </SimpleForm>
);

// ボタンのカスタマイズは(新しいボタングループを作ったのちに、
// カスタムツールバーへ組み込み後、propsでsimpleformに渡す
export const DoctorEditToolbar = ({
  saveButtonLabel,
}: {
  saveButtonLabel: string;
}) => (
  <Toolbar style={{ backgroundColor: "white" }}>
    <SaveButton label={saveButtonLabel} />
  </Toolbar>
);

export const DoctorActions = (props: any) => (
  <TopToolbar style={{ marginBottom: "12px" }}>
    <CreateButton
      sx={{
        fontSize: "16px",
        padding: "8px",
        backgroundColor: "primary.dark",
        color: "white",
        ":hover": {
          backgroundColor: "primary.dark",
        },
      }}
      label="新しい医師を追加"
    />
  </TopToolbar>
);

// バリデーション
const validateLastName = [required("医師名（姓）を入力してください")];
const validateFirstName = [required("医師名（名）を入力してください")];
const validateLastKana = [
  required("医師名（セイ）を入力してください"),
  regex(/^[ァ-ンヴー]*$/, "全角カナで入力してください"),
];
const validateFirstKana = [
  required("医師名（メイ）を入力してください"),
  regex(/^[ァ-ンヴー]*$/, "全角カナで入力してください"),
];
