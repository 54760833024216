import { UserMenu, MenuItemLink, AppBar, Layout, Logout } from "react-admin";
import { ProfileProvider } from "../pages/ProfileEdit";
import CustomAppBar from "./CustomAppBar";
import SettingsIcon from "@mui/icons-material/Settings";
import { MyMenu } from "./myMenu";

const MyLayout = (props: any) => (
  <ProfileProvider>
    <Layout {...props} appBar={CustomAppBar} title="amidex" menu={MyMenu} />
  </ProfileProvider>
);

export default MyLayout;
