import * as React from "react";

import {
  Menu,
  MenuItemLink,
  usePermissions,
  useResourceDefinitions,
} from "react-admin";

import SendIcon from "@mui/icons-material/Send";
import MailIcon from "@mui/icons-material/Mail";

export const MyMenu = () => {
  const resources = useResourceDefinitions();
  const { permissions } = usePermissions();
  const handleContactClick = () => {
    window.open("https://amidex.co.jp/contact/", "_blank");
  };
  return (
    <Menu>
      {permissions.is_superuser === false && (
        <MenuItemLink
          to="/orders/create"
          primaryText="新規注文"
          leftIcon={<SendIcon />}
        />
      )}
      {/* app.tsxのadmin可にあるものも読み込む */}
      {Object.keys(resources)
        .filter((name) => resources[name].hasList)
        .map((name) => {
          const Icon = resources[name].icon ? resources[name].icon : null;
          return (
            <MenuItemLink
              key={name}
              to={`/${name}`}
              primaryText={
                resources[name].options && resources[name].options.label
              }
              leftIcon={<Icon />}
            />
          );
        })}
      {permissions.is_superuser === false && (
        <MenuItemLink
          to="/contact"
          onClick={handleContactClick}
          primaryText="お問い合わせ"
          leftIcon={<MailIcon />}
        />
      )}
    </Menu>
  );
};
