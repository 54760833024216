import { FormEvent, useState } from "react";

import { useLogin, useNotify } from "react-admin";
import Auth from "../components/Auth";

import Button from "@mui/material/Button";
import { Link } from "react-router-dom";
import authStyles from "../components/authStyles";

const Login = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const login = useLogin();
  const notify = useNotify();

  const submit = (e: FormEvent) => {
    e.preventDefault();
    login({ email, password }).catch((e) => {
      const msg = e.response?.data?.detail;
      if (msg) {
        notify(msg, { type: "error" });
      } else {
        notify("Network error", { type: "error" });
      }
    });
  };

  const handleContactClick = () => {
    window.open("https://amidex.co.jp/contact/", "_blank");
  };

  return (
    <Auth
      setEmail={setEmail}
      setPassword={setPassword}
      actionName="Sign in"
      submit={submit}
      extraActions={
        <div>
          <a
            style={{
              paddingTop: "20px",
              marginLeft: "-16px",
              fontWeight: "bold",
              cursor: "pointer",
            }}
            onClick={handleContactClick}
          >
            お問い合わせ
          </a>
        </div>
      }
    />
  );
};

export default Login;
