import { createBrowserHistory as createHistory } from "history";
import simpleRestProvider from "ra-data-simple-rest";
import { Admin, fetchUtils, Resource, CustomRoutes } from "react-admin";
import { Route } from "react-router";
import MyLayout from "./components/AdminLayout";
import LoginPage from "./pages/Login";
import { ProfileEdit } from "./pages/ProfileEdit";
import Register from "./pages/Register";
import dataProvider from "./providers/dataProvider";
import authProvider from "./providers/authProvider";
import { basePath } from "./providers/env";
import PostIcon from "@mui/icons-material/PostAdd";
import PersonIcon from "@mui/icons-material/Person";
import ListAltIcon from "@mui/icons-material/ListAlt";
import SupervisedUserCircleIcon from "@mui/icons-material/SupervisedUserCircle";
import polyglotI18nProvider from "ra-i18n-polyglot";
import japaneseMessages from "@bicstone/ra-language-japanese";
import {
  AccountList,
  AccountCreate,
  AccountEdit,
  AccountShow,
} from "./pages/Accounts";
import {
  SysAccountCreate,
  SysAccountEdit,
  SysAccountEditBase,
  SysAccountList,
} from "./pages/SysAccounts";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { DoctorCreate, DoctorEdit, DoctorList } from "./pages/Doctors";
import { OrderCreate, OrderList, OrderShow } from "./pages/Orders";
import { OrderedList, OrderedShow, OrderedEdit } from "./pages/Ordered";
import { MyMenu } from "./components/myMenu";

const i18nProvider = polyglotI18nProvider(() => japaneseMessages, "ja");

const customTheme = createTheme({
  palette: {
    primary: {
      main: "#1e2e50",
    },
  },
});

const App = () => {
  return (
    <ThemeProvider theme={customTheme}>
      <Admin
        disableTelemetry
        dataProvider={dataProvider}
        authProvider={authProvider}
        loginPage={LoginPage}
        history={createHistory()}
        layout={MyLayout}
        title="Amidex 発注システム"
        menu={MyMenu}
        i18nProvider={i18nProvider}
      >
        <CustomRoutes>
          <Route path="/my-profile" element={<ProfileEdit />} />
        </CustomRoutes>
        <CustomRoutes noLayout>
          <Route path="/register" element={<Register />} />
        </CustomRoutes>
        {(permissions) => [
          permissions.is_superuser === true ? (
            <>
              <Resource
                name="ordered"
                options={{ label: "受注履歴" }}
                list={OrderedList}
                show={OrderedShow}
                edit={OrderedEdit}
                icon={PostIcon}
              />
              <Resource
                options={{ label: "医院アカウント情報" }}
                name="accounts"
                list={AccountList}
                show={AccountShow}
                edit={AccountEdit}
                create={AccountCreate}
                icon={SupervisedUserCircleIcon}
              />
              <Resource
                options={{ label: "管理アカウント情報" }}
                name="sysaccounts"
                list={SysAccountList}
                create={SysAccountCreate}
                edit={SysAccountEdit}
                icon={SupervisedUserCircleIcon}
              />
              <Resource name="all_doctors" recordRepresentation="name" />
            </>
          ) : (
            <>
              <Resource
                name="orders"
                options={{ label: "注文履歴" }}
                list={OrderList}
                create={OrderCreate}
                show={OrderShow}
                icon={ListAltIcon}
              />
              <Resource
                name="doctors"
                options={{ label: "医師情報" }}
                list={DoctorList}
                edit={DoctorEdit}
                create={DoctorCreate}
                icon={PersonIcon}
                recordRepresentation="name"
              />
            </>
          ),
        ]}
      </Admin>
    </ThemeProvider>
  );
};

export default App;
