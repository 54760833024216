import {
  Create,
  Datagrid,
  Edit,
  EditButton,
  Show,
  List,
  SimpleForm,
  TextField,
  ReferenceField,
  FunctionField,
  DateField,
  TextInput,
  SelectField,
  SelectInput,
  BooleanInput,
  ReferenceInput,
  AutocompleteInput,
  useRecordContext,
  DateInput,
  CheckboxGroupInput,
  RadioButtonGroupInput,
  ChipField,
  FormDataConsumer,
  Labeled,
  SaveButton,
  Toolbar,
  TopToolbar,
  CreateButton,
  required,
  regex,
  PasswordInput,
} from "react-admin";
import { Stack, Typography, Grid } from "@mui/material";
import type { Order } from "../types";
import { RequireLabel } from "../components/requireLabel";

const accountFilters = [
  <TextInput source="clinic_name" label="医院名" alwaysOn />,
  <TextInput source="email" label="メールアドレス" alwaysOn />,
  <TextInput source="tel_number" label="電話番号" alwaysOn />,
  <TextInput source="representative_name" label="代表者" alwaysOn />,
];
export const AccountList = (props: any) => (
  <List
    {...props}
    filters={accountFilters}
    title="Amidex　発注システム　医院アカウント情報"
    style={{ paddingTop: "40px" }}
    exporter={false}
  >
    <Datagrid rowClick="show">
      <TextField source="clinic_name" label="医院名" />
      <TextField source="email" label="e-mail" />
      <SelectField
        source="prefecture_code"
        choices={prefectureCodes}
        label="都道府県"
      />
      <TextField source="tel_number" label="電話番号" />
      <FunctionField
        source="representative_last_kana"
        render={(record) =>
          `${record.representative_last_name} ${record.representative_first_name}`
        }
        label="代表者氏名"
      />
      <DateField source="created" label="登録日" />
      <EditButton label="編集" />
    </Datagrid>
  </List>
);

export const AccountEditToolbar = ({
  saveButtonLabel,
  showSaveButton = true,
}: {
  saveButtonLabel: string;
  showSaveButton?: boolean;
}) => (
  <Toolbar style={{ backgroundColor: "white" }}>
    {showSaveButton && <SaveButton label={saveButtonLabel} />}
  </Toolbar>
);

export const AccountEdit = (props: any) => (
  <Edit {...props} title="医院情報">
    <AccountEditBase
      toolbar={<AccountEditToolbar saveButtonLabel="医院情報編集" />}
      disabled={false}
      hasResetPassword={false}
    />
  </Edit>
);

export const AccountEditBase = ({
  toolbar = <Toolbar />,
  disabled = true,
  hasResetPassword = true,
  ...props
}) => (
  <SimpleForm {...props} toolbar={toolbar}>
    <Typography variant="h6" fontWeight="bold" gutterBottom>
      医院情報
    </Typography>
    <Grid container spacing={2}>
      <Grid item xs={2} mt={2}>
        <Typography variant="subtitle1">
          医院名
          <RequireLabel />
        </Typography>
      </Grid>
      <Grid item xs={10}>
        <TextInput
          source="clinic_name"
          label="医院名"
          sx={{ width: "400px" }}
          validate={validateClinicName}
          disabled={disabled}
        />
      </Grid>
      <Grid item xs={2} mt={2}>
        <Typography variant="subtitle1">
          医院名（カナ）
          <RequireLabel />
        </Typography>
      </Grid>
      <Grid item xs={10}>
        <TextInput
          source="clinic_kana"
          label="医院名（全角カナ）"
          sx={{ width: "400px" }}
          validate={validateClinicKana}
          disabled={disabled}
        />
      </Grid>
      <Grid item xs={2} mt={2}>
        <Typography variant="subtitle1">
          メールアドレス
          <RequireLabel />
        </Typography>
      </Grid>
      <Grid item xs={10}>
        <TextInput
          source="email"
          label="Email"
          sx={{ width: "400px" }}
          validate={validateEmail}
          disabled={disabled}
        />
      </Grid>
    </Grid>

    <Grid container spacing={2}>
      <Grid item xs={2} mt={2}>
        <Typography variant="subtitle1">
          代表者氏名
          <RequireLabel />
        </Typography>
      </Grid>
      <Grid item xs="auto">
        <TextInput
          source="representative_last_name"
          label="姓"
          validate={validateRepresentativeLastName}
          disabled={disabled}
        />
      </Grid>
      <Grid item xs="auto">
        <TextInput
          source="representative_first_name"
          label="名"
          validate={validateRepresentativeFirstName}
          disabled={disabled}
        />
      </Grid>
    </Grid>
    <Grid container spacing={2}>
      <Grid item xs={2} mt={2}>
        <Typography variant="subtitle1">
          代表者氏名（カナ）
          <RequireLabel />
        </Typography>
      </Grid>
      <Grid item xs="auto">
        <TextInput
          source="representative_last_kana"
          label="セイ"
          validate={validateRepresentativeLastKana}
          disabled={disabled}
        />
      </Grid>
      <Grid item xs="auto">
        <TextInput
          source="representative_first_kana"
          label="メイ"
          validate={validateRepresentativeFirstKana}
          disabled={disabled}
        />
      </Grid>
    </Grid>
    <Grid container spacing={2}>
      <Grid item xs={2} mt={2}>
        <Typography variant="subtitle1">
          請求書送付先住所
          <RequireLabel />
        </Typography>
      </Grid>
      <Grid container item xs={10}>
        <Grid container spacing={2}>
          <Grid item xs="auto">
            <Labeled>
              <TextInput
                source="post_code"
                label="郵便番号"
                validate={validatePostCode}
                disabled={disabled}
              />
            </Labeled>
          </Grid>
          <Grid item xs="auto">
            <Labeled>
              <SelectInput
                source="prefecture_code"
                label="都道府県"
                choices={prefectureCodes}
                validate={validatePrefectureCode}
                disabled={disabled}
              />
            </Labeled>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Labeled>
            <TextInput
              source="address_1"
              label="市区町村"
              sx={{ width: "400px" }}
              validate={validateAddress1}
              disabled={disabled}
            />
          </Labeled>
        </Grid>
        <Grid item xs={12}>
          <Labeled>
            <TextInput
              source="address_2"
              label="番地"
              sx={{ width: "400px" }}
              validate={validateAddress2}
              disabled={disabled}
            />
          </Labeled>
        </Grid>

        <Grid item xs={12}>
          <Labeled>
            <TextInput
              source="address_3"
              label="建物・部屋番号"
              sx={{ width: "400px" }}
              disabled={disabled}
            />
          </Labeled>
        </Grid>
        <Grid item xs={12}>
          <Labeled>
            <TextInput
              source="tel_number"
              label="電話番号"
              sx={{ width: "400px" }}
              validate={validateTelNumber}
              disabled={disabled}
            />
          </Labeled>
        </Grid>
      </Grid>
      <Grid item xs={10}>
        <Grid item xs={2} mt={2} />

        <Grid item xs={12}>
          <CheckboxGroupInput
            source="shipping_is_same"
            label=""
            choices={[
              {
                id: 0,
                name: "商品送付先が上記と異なる場合は、こちらにチェックを入れてください。",
              },
            ]}
            disabled={disabled}
          />
        </Grid>
      </Grid>

      <FormDataConsumer>
        {({ formData, ...rest }) => {
          // shipping_is_same が配列でない場合は、配列に変換する
          const shippingIsSameArray = Array.isArray(formData.shipping_is_same)
            ? formData.shipping_is_same
            : [formData.shipping_is_same];

          return (
            shippingIsSameArray.includes(0) && (
              <Grid container spacing={2} style={{ paddingLeft: "20px" }}>
                <Grid item xs={2} mt={2}>
                  <Typography variant="subtitle1">
                    商品送付先住所
                    <RequireLabel />
                  </Typography>
                </Grid>
                <Grid container xs={10} style={{ paddingLeft: "16px" }}>
                  <Grid container spacing={2}>
                    <Grid item xs="auto">
                      <Labeled>
                        <TextInput
                          source="shipping_post_code"
                          label="郵便番号"
                          disabled={disabled}
                          validate={validatePostCode}
                        />
                      </Labeled>
                    </Grid>
                    <Grid item xs="auto">
                      <Labeled>
                        <SelectInput
                          source="shipping_prefecture_code"
                          label="都道府県"
                          choices={prefectureCodes}
                          disabled={disabled}
                          validate={validatePrefectureCode}
                        />
                      </Labeled>
                    </Grid>
                  </Grid>

                  <Grid item xs={12}>
                    <Labeled>
                      <TextInput
                        source="shipping_address_1"
                        label="市区町村"
                        sx={{ width: "400px" }}
                        disabled={disabled}
                        validate={validateAddress1}
                      />
                    </Labeled>
                  </Grid>
                  <Grid item xs={12}>
                    <Labeled>
                      <TextInput
                        source="shipping_address_2"
                        label="番地"
                        sx={{ width: "400px" }}
                        disabled={disabled}
                        validate={validateAddress2}
                      />
                    </Labeled>
                  </Grid>
                  <Grid item xs={12}>
                    <Labeled>
                      <TextInput
                        source="shipping_address_3"
                        label="建物・部屋番号"
                        sx={{ width: "400px" }}
                        disabled={disabled}
                      />
                    </Labeled>
                  </Grid>
                  <Grid item xs={12}>
                    <Labeled>
                      <TextInput
                        source="shipping_tel_number"
                        label="電話番号"
                        sx={{ width: "400px" }}
                        disabled={disabled}
                        validate={validateTelNumber}
                      />
                    </Labeled>
                  </Grid>
                </Grid>
              </Grid>
            )
          );
        }}
      </FormDataConsumer>

      {!hasResetPassword && (
        <Grid container spacing={2} style={{ paddingLeft: "20px" }}>
          <Grid item xs={2} mt={1}>
            <Typography variant="subtitle1">パスワード再設定</Typography>
          </Grid>
          <Grid container item xs={10}>
            <BooleanInput
              source="hasPassword"
              label="新しいパスワードを設定する"
            />
          </Grid>
        </Grid>
      )}

      <FormDataConsumer>
        {({ formData, ...rest }) =>
          formData.hasPassword && (
            <Grid container spacing={2} style={{ paddingLeft: "20px" }}>
              <Grid item xs={2} mt={2}>
                <Typography variant="subtitle1">新しいパスワード</Typography>
              </Grid>
              <Grid container item xs={10}>
                <Grid item xs={12}>
                  <PasswordInput
                    source="new_password"
                    inputProps={{ autocomplete: "current-password" }}
                    {...rest}
                    validate={required()}
                    disabled={disabled}
                  />
                </Grid>
              </Grid>
              <Grid item xs={2} mt={2}>
                <Typography variant="subtitle1">
                  新しいパスワード（確認）
                </Typography>
              </Grid>
              <Grid container item xs={10}>
                <Grid item xs={12}>
                  <PasswordInput
                    source="confirm_password"
                    validate={[required(), equalToPassword]}
                    {...rest}
                    disabled={disabled}
                  />
                </Grid>
              </Grid>
            </Grid>
          )
        }
      </FormDataConsumer>
    </Grid>
  </SimpleForm>
);

export const AccountCreate = (props: any) => {
  return (
    <Create {...props} title="新規医院登録">
      <AccountEditBase saveButtonLabel="医院情報登録" disabled={false} />
    </Create>
  );
};

export const AccountShow = (props: any) => (
  <Show {...props}>
    <AccountEditBase
      toolbar={
        <AccountEditToolbar saveButtonLabel="医院情報" showSaveButton={false} />
      }
    />
  </Show>
);

const prefectureCodes = [
  { id: 1, name: "北海道" },
  { id: 2, name: "青森県" },
  { id: 3, name: "岩手県" },
  { id: 4, name: "宮城県" },
  { id: 5, name: "秋田県" },
  { id: 6, name: "山形県" },
  { id: 7, name: "福島県" },
  { id: 8, name: "茨城県" },
  { id: 9, name: "栃木県" },
  { id: 10, name: "群馬県" },
  { id: 11, name: "埼玉県" },
  { id: 12, name: "千葉県" },
  { id: 13, name: "東京都" },
  { id: 14, name: "神奈川県" },
  { id: 15, name: "新潟県" },
  { id: 16, name: "富山県" },
  { id: 17, name: "石川県" },
  { id: 18, name: "福井県" },
  { id: 19, name: "山梨県" },
  { id: 20, name: "長野県" },
  { id: 21, name: "岐阜県" },
  { id: 22, name: "静岡県" },
  { id: 23, name: "愛知県" },
  { id: 24, name: "三重県" },
  { id: 25, name: "滋賀県" },
  { id: 26, name: "京都府" },
  { id: 27, name: "大阪府" },
  { id: 28, name: "兵庫県" },
  { id: 29, name: "奈良県" },
  { id: 30, name: "和歌山県" },
  { id: 31, name: "鳥取県" },
  { id: 32, name: "島根県" },
  { id: 33, name: "岡山県" },
  { id: 34, name: "広島県" },
  { id: 35, name: "山口県" },
  { id: 36, name: "徳島県" },
  { id: 37, name: "香川県" },
  { id: 38, name: "愛媛県" },
  { id: 39, name: "高知県" },
  { id: 40, name: "福岡県" },
  { id: 41, name: "佐賀県" },
  { id: 42, name: "長崎県" },
  { id: 43, name: "熊本県" },
  { id: 44, name: "大分県" },
  { id: 45, name: "宮崎県" },
  { id: 46, name: "鹿児島県" },
  { id: 47, name: "沖縄県" },
];

const CustomToolbar = () => (
  <Toolbar>
    <SaveButton label="医院情報編集" />
  </Toolbar>
);

export const AccountActions = (props: any) => (
  <TopToolbar style={{ marginBottom: "12px" }}>
    <CreateButton
      sx={{
        fontSize: "16px",
        padding: "8px",
        backgroundColor: "primary.dark",
        color: "white",
        ":hover": {
          backgroundColor: "primary.dark",
        },
      }}
      label="新しい医院を追加"
    />
  </TopToolbar>
);

// バリデーション
const validateClinicName = [required("医院名を入力してください")];
const validateClinicKana = [
  required("医院名（カナ）を入力してください"),
  regex(/^[ァ-ンヴー]*$/, "全角カナで入力してください"),
];
const validateEmail = [required("メールアドレスを入力してください")];
const validateRepresentativeLastName = [
  required("代表者氏名（姓）を入力してください"),
];
const validateRepresentativeFirstName = [
  required("代表者氏名（名）を入力してください"),
];
const validateRepresentativeLastKana = [
  required("代表者氏名（セイ）を入力してください"),
  regex(/^[ァ-ンヴー]*$/, "全角カナで入力してください"),
];
const validateRepresentativeFirstKana = [
  required("代表者氏名（メイ）を入力してください"),
  regex(/^[ァ-ンヴー]*$/, "全角カナで入力してください"),
];
const validatePostCode = [
  required("郵便番号を入力してください"),
  regex(/^[0-9]+$/, "数字で入力してください"),
];
const validatePrefectureCode = [required("都道府県を入力してください")];
const validateAddress1 = [required("市区町村を入力してください")];
const validateAddress2 = [required("番地を入力してください")];
const validateTelNumber = [
  required("電話番号を入力してください"),
  regex(/^[0-9]+$/, "数字で入力してください/ハイフンは使わないでください"),
];

const equalToPassword = (value, allValues) => {
  if (value !== allValues.new_password) {
    return "同じパスワードを2回入力してください";
  }
  if (value.length < 8) {
    return "パスワードは8文字以上必要です";
  }
  return undefined;
};
