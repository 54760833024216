import {
  Create,
  Datagrid,
  Edit,
  Show,
  List,
  SimpleForm,
  ReferenceField,
  FunctionField,
  DateField,
  TextInput,
  SelectField,
  SelectInput,
  BooleanInput,
  FileInput,
  FileField,
  ReferenceInput,
  AutocompleteInput,
  useRecordContext,
  DateInput,
  CheckboxGroupInput,
  RadioButtonGroupInput,
  ChipField,
  FormDataConsumer,
  required,
  Toolbar,
  regex,
  minValue,
} from "react-admin";
import { Typography, Grid, Card, Alert } from "@mui/material";
import type { Order } from "./../types";
import { OrderWithConfirmButton } from "../components/OrderWithConfirmButton";
import DentalMap from "../components/dentalMap";
import {
  deliveryStatusMap,
  makeUpperIncludeNeighborSources,
  makeLowerIncludeNeighborSources,
  makeConnectionList,
  canConnect,
  makeDentalTexts,
  PRODUCT_CHOICES,
  replaceToConnectionText,
  DENTAL_TARGET_CHOICES_REVERSE,
  DENTAL_TARGET_CHOICES,
  FINISHING_TYPE_CHOICES,
  NEED_PRE_CONFIRMATION_CHOICES,
  METHOD_TYPE_CHOICES,
  SEX_CHOICES,
  DELIVERY_STATUS_CHOICES_WITH_CANCEL,
  METHOD_TYPE_MAP,
  METHOD_TYPE_MAP_OLD,
  NEED_GUM_RETRACTION_CHOICES,
  ORTHODONTIC_TREATMENT_PLAN_CHOICES,
  NEED_PRE_CONFIRMATION_NO_NEED,
  oneWeekLaterDate,
  twoWeekLaterDate,
  threeWeekLaterDate,
} from "../functions/OrdersFunction";
import { RequireLabel } from "../components/requireLabel";
import { DelayedFileField } from "../components/DelayedFileField";

const orderFilters = [
  <ReferenceInput source="doctor_id" reference="doctors" allowEmpty alwaysOn>
    <SelectInput
      optionText={(record) => `${record.last_name} ${record.first_name}`}
      label="医師名"
    />
  </ReferenceInput>,
  <TextInput source="last_kana" label="患者セイ" alwaysOn />,
  <TextInput source="first_kana" label="患者メイ" alwaysOn />,
  <DateInput source="order_start_date" label="開始日" alwaysOn />,
  <DateInput source="order_end_date" label="終了日" alwaysOn />,
  <SelectInput
    source="delivery_status"
    alwaysOn
    label="納品状態"
    choices={DELIVERY_STATUS_CHOICES_WITH_CANCEL}
    optionText="label"
    optionValue="_id"
  />,
];

const DENTAL_TEXTS = makeDentalTexts();

export const OrderList = (props: any) => (
  <List
    {...props}
    filters={orderFilters}
    title="Amidex　発注システム　注文履歴"
    style={{ paddingTop: "40px" }}
    hasCreate={false}
    exporter={false}
  >
    <Datagrid rowClick="show" bulkActionButtons={false}>
      <DateField source="created" label="発注日" />
      <ReferenceField
        source="doctor_id"
        reference="doctors"
        link={false}
        label="医師名"
      >
        <FunctionField
          render={(record: any) => `${record.last_name} ${record.first_name}`}
        />
      </ReferenceField>
      <FunctionField<Order>
        source="last_kana"
        render={(record) => `${record.last_kana} ${record.first_kana}`}
        label="患者氏名（カナ）"
      />
      <SelectField
        source="sex"
        choices={[
          { id: 0, name: "" },
          { id: 1, name: "男性" },
          { id: 2, name: "女性" },
          { id: 3, name: "未回答" },
        ]}
        label="性別"
      />
      <DateField source="birthday" label="生年月日" />
      <FunctionField
        label="納品状態"
        source="delivery_status"
        render={(record: any) => (
          <ChipField
            // variant="outlined"
            color={deliveryStatusMap.get(record.delivery_status)?.color}
            source="delivery_status"
            record={{
              delivery_status: deliveryStatusMap.get(record.delivery_status)
                ?.name,
            }}
          />
        )}
      />
    </Datagrid>
  </List>
);

export const OrderEdit = (props: any) => (
  <Edit {...props} title="Amidex　発注システム　注文詳細">
    <SimpleForm validate={validateForm}>
      <TextInput source="name" />
    </SimpleForm>
  </Edit>
);

export const OrderCreateToolbar = (props: any) => (
  <Toolbar
    style={{
      backgroundColor: "white",
      display: "flex",
      justifyContent: "center",
    }}
  >
    <OrderWithConfirmButton />
  </Toolbar>
);

export const OrderCreate = (props: any) => {
  return (
    <Create title="Amidex　発注システム　新規注文">
      <SimpleForm
        mode="onBlur"
        reValidateMode="onBlur"
        defaultValues={{
          birthday: "2000-01-01",
        }}
        toolbar={<OrderCreateToolbar />}
      >
        <OrderEditBase />
      </SimpleForm>
    </Create>
  );
};

export const OrderShow = (props: any) => (
  <Show {...props}>
    <SimpleForm title="Amidex　発注システム　注文詳細" toolbar={false}>
      <OrderEditBase isShowMode />
    </SimpleForm>
  </Show>
);

//新規注文の場合、モード日付が未入力または最小日付未満なら注意メッセージを表示
const AlertDeliveryDateMessage = (props: any) => {
  const { targetDate, minDate, isShowMode } = props;
  if (isShowMode || (targetDate && targetDate >= minDate)) {
    return null;
  }
  return (
    <Typography fontSize={12} color={"error"} sx={{ mt: "-25px", mb: "20px" }}>
      {"納品日の希望がある場合は、" +
        minDate.replaceAll("-", "/") +
        "以降の日付を選択してください"}
    </Typography>
  );
};

const OrderEditBase = ({ isShowMode = false }) => {
  const record = useRecordContext();
  return (
    <>
      <Typography variant="h6" fontWeight="bold" gutterBottom>
        基本情報
        <RequireLabel />
      </Typography>
      <Grid
        container
        spacing={2}
        style={!isShowMode ? { paddingBottom: "24px" } : {}}
      >
        <Grid item xs={2} mt={2}>
          <Typography variant="subtitle1">医師名</Typography>
        </Grid>
        <Grid item xs={10}>
          <ReferenceInput source="doctor_id" reference="doctors">
            <AutocompleteInput
              label={false}
              source="id"
              optionText={(record) =>
                `${record.last_name} ${record.first_name}`
              }
              sx={{ width: "300px" }}
              validate={required("必須です")}
              emptyText="選択してください"
              disabled={isShowMode}
            />
          </ReferenceInput>
          {!isShowMode && (
            <span style={{ color: "red" }}>
              初回利用時は左側のメニューの「医師情報」をクリックし、「医師名」の登録をお願いします。
            </span>
          )}
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs={2} mt={2}>
          <Typography variant="subtitle1">患者名（カナ）</Typography>
        </Grid>
        <Grid item xs="auto">
          <TextInput
            source="last_kana"
            label={false}
            disabled={isShowMode}
            validate={validatePatientKana}
          />
        </Grid>
        <Grid item xs="auto">
          <TextInput
            source="first_kana"
            label={false}
            disabled={isShowMode}
            validate={validatePatientKana}
          />
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs={2}>
          <Typography variant="subtitle1">性別</Typography>
        </Grid>
        <Grid item xs={10}>
          <SelectInput
            source="sex"
            label={false}
            choices={SEX_CHOICES}
            disabled={isShowMode}
            validate={required("必須です")}
          />
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs={2}>
          <Typography variant="subtitle1">生年月日</Typography>
        </Grid>
        <Grid item xs={10}>
          <DateInput
            source="birthday"
            label={false}
            disabled={isShowMode}
            validate={required("必須です")}
          />
        </Grid>
      </Grid>
      <Typography
        variant="h6"
        fontWeight="bold"
        gutterBottom
        sx={{ mt: "20px" }}
      >
        制作する歯にチェックを入れてください。
        <RequireLabel />
      </Typography>
      <Grid container ml="10px" sx={{ display: "flex", alignItems: "center" }}>
        <Grid item xs={2.5}>
          <DentalMap
            src="/dental_image.png"
            mapName="ImageMap"
            isShowMode={isShowMode}
          />
        </Grid>
        <Grid item xs mt="40px">
          <Grid container>
            <Grid
              item
              xs="auto"
              style={{
                textAlign: "left",
                borderBottom: "2px solid",
                borderRight: "2px solid",
                paddingRight: "16px",
                paddingBottom: "8px",
              }}
            >
              <p style={{ textAlign: "left", marginBottom: "0" }}>上顎(右)</p>
              <CheckboxGroupInput
                source="quadrant_1"
                label=""
                labelPlacement="bottom"
                options={{
                  sx: {
                    paddingLeft: "0px",
                    paddingRight: "0px",
                    marginLeft: "-10px",
                    marginRight: "-10px",
                  },
                }}
                choices={DENTAL_TARGET_CHOICES_REVERSE}
                disabled={isShowMode}
              />
            </Grid>
            <Grid
              item
              xs="auto"
              style={{
                borderBottom: "2px solid",
                paddingLeft: "16px",
                paddingBottom: "8px",
              }}
            >
              <p style={{ textAlign: "right", marginBottom: "0" }}>上顎(左)</p>
              <CheckboxGroupInput
                source="quadrant_2"
                label=""
                labelPlacement="bottom"
                options={{
                  sx: {
                    paddingLeft: "0px",
                    paddingRight: "0px",
                    marginLeft: "-10px",
                    marginRight: "-10px",
                  },
                }}
                choices={DENTAL_TARGET_CHOICES}
                disabled={isShowMode}
              />
            </Grid>
          </Grid>
          <Grid container>
            <Grid
              item
              xs="auto"
              style={{
                borderRight: "2px solid",
                paddingRight: "16px",
                paddingTop: "16px",
              }}
            >
              <CheckboxGroupInput
                source="quadrant_3"
                label=""
                labelPlacement="top"
                options={{
                  sx: {
                    paddingLeft: "0px",
                    paddingRight: "0px",
                    marginLeft: "-10px",
                    marginRight: "-10px",
                  },
                }}
                choices={DENTAL_TARGET_CHOICES_REVERSE}
                disabled={isShowMode}
              />
              <p style={{ textAlign: "left", marginTop: "-16px" }}>下顎(右)</p>
            </Grid>
            <Grid
              item
              xs="auto"
              style={{ paddingLeft: "16px", paddingTop: "16px" }}
            >
              <CheckboxGroupInput
                source="quadrant_4"
                label=""
                labelPlacement="top"
                options={{
                  sx: {
                    paddingLeft: "0px",
                    paddingRight: "0px",
                    marginLeft: "-10px",
                    marginRight: "-10px",
                  },
                }}
                choices={DENTAL_TARGET_CHOICES}
                disabled={isShowMode}
              />
              <p style={{ textAlign: "right", marginTop: "-16px" }}>下顎(左)</p>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      {!isShowMode && (
        <Grid container spacing={2} sx={{ paddingTop: "20px" }}>
          <Grid item xs={0.8} />
          <Grid item xs={8}>
            <Alert
              variant="outlined"
              severity="info"
              sx={{ color: "#000000DE", fontWeight: "400", fontSize: "1rem" }}
            >
              ※ブリッジの場合、支台歯にもチェックを入れてください。また、下記の「制作する歯の種類」にて支台歯に「ブリッジ」を選択して下さい。
              <br></br>
              これらが適切に行われない場合、概算価格が正しく計算されない可能性があります。
            </Alert>
          </Grid>
        </Grid>
      )}

      <FormDataConsumer>
        {({ formData, scopedFormData, ...rest }) => {
          if (
            (!formData["quadrant_1"] || formData["quadrant_1"].length === 0) &&
            (!formData["quadrant_2"] || formData["quadrant_2"].length === 0) &&
            (!formData["quadrant_3"] || formData["quadrant_3"].length === 0) &&
            (!formData["quadrant_4"] || formData["quadrant_4"].length === 0)
          )
            return;

          // fromData["quadrant_X"]の中から下記の配列をそれぞれを取り出す
          //   xxxxCheckedSources:         画面でチェックされている歯(product_type_X_X形式)
          //   xxxxIncluedNeighborSources: 画面でチェックされている歯+隣り合う歯(product_type_X_X形式)
          const [upperCheckedSources, upperIncluedNeighborSources] =
            makeUpperIncludeNeighborSources(formData);
          const [lowerCheckedSources, lowerIncluedNeighborSources] =
            makeLowerIncludeNeighborSources(formData);

          // TODO: 本当は上の歯、下の歯、間開く場合を配列で分けて大元からループすると ==== の線を入れられる
          // そうすると、canConnectも先頭かどうかで判定できるので簡単になるはず
          const components = [
            ...upperIncluedNeighborSources,
            ...lowerIncluedNeighborSources,
          ].map((dental_target: string) => {
            return upperCheckedSources.includes(dental_target) ||
              lowerCheckedSources.includes(dental_target) ? (
              <Grid container spacing={2} key={dental_target}>
                <Grid item xs={3.5}>
                  <Typography variant="subtitle1" mt={2}>
                    {DENTAL_TEXTS.get(dental_target)}
                  </Typography>
                </Grid>
                <Grid item xs>
                  <SelectInput
                    source={dental_target}
                    label={false}
                    sx={{ width: "200px" }}
                    isRequired={true}
                    choices={PRODUCT_CHOICES}
                    disabled={isShowMode}
                  />
                </Grid>
              </Grid>
            ) : (
              <Grid container spacing={2} key={dental_target}>
                <Grid item xs={3.5} mt={2}>
                  <Typography variant="subtitle1">
                    {DENTAL_TEXTS.get(dental_target)}
                  </Typography>
                </Grid>
                <Grid item xs>
                  <SelectInput
                    source={dental_target}
                    label={false}
                    sx={{ width: "200px" }}
                    isRequired={true}
                    defaultValue={0}
                    // formatを入れて強制的に0固定にする
                    format={(value) => 0}
                    choices={[{ id: 0, name: "制作無し" }]}
                    disabled={isShowMode}
                  />
                </Grid>
              </Grid>
            );
          });

          // 連結のコンポーネントは歯の種類のコンポーネントから先頭を切り取ったものとする
          // ※隣り合う歯のうち、後ろを基準に、前の歯に対して連結と考える
          const connectionList = makeConnectionList(
            upperIncluedNeighborSources,
            lowerIncluedNeighborSources
          );
          const connectionComponents = connectionList.map(
            (dental_target: string) => {
              return canConnect(
                dental_target,
                upperIncluedNeighborSources,
                lowerIncluedNeighborSources
              ) ? (
                <BooleanInput
                  key={dental_target}
                  sx={{ pt: "23px" }}
                  source={replaceToConnectionText(dental_target)}
                  label={"連結する"}
                  disabled={isShowMode}
                />
              ) : (
                <Grid item xs sx={{ height: "85px" }} />
              );
            }
          );
          return (
            <>
              <Typography
                variant="h6"
                fontWeight="bold"
                gutterBottom
                sx={{ mt: "20px" }}
              >
                「制作する歯の種類」をプルダウンから選択してください。
                <RequireLabel />
              </Typography>
              <Grid container ml={10}>
                <Grid item xs={4} children={components}></Grid>
                <Grid
                  item
                  xs
                  sx={{ pt: "30px" }}
                  children={connectionComponents}
                ></Grid>
              </Grid>
              <Grid container spacing={2}>
                <Grid item xs={0.8} />
                <Grid item xs={8.5}>
                  <Alert
                    variant="outlined"
                    severity="info"
                    sx={{
                      color: "#000000DE",
                      fontWeight: "400",
                      fontSize: "1rem",
                    }}
                  >
                    ・卵型の場合、インデックスの底に穴が空き、テフロンテープを巻いていただく必要があります。
                    <br />
                    ・離底型の場合、底面の仕上げが行いやすくなります。
                    <br />
                    ・その他指示がある場合は「制作する歯に関する補足事項」にご記入ください。
                  </Alert>
                </Grid>
              </Grid>
            </>
          );
        }}
      </FormDataConsumer>
      <Typography
        variant="h6"
        fontWeight="bold"
        gutterBottom
        sx={{ mt: "20px" }}
      >
        ※制作する歯に関する補足事項（任意）
      </Typography>
      <Grid container spacing={2}>
        <Grid item xs={0.8} />
        <Grid item xs={6}>
          <TextInput
            multiline
            label={false}
            source="supplement_info"
            fullWidth
            rows={4}
            placeholder={
              isShowMode
                ? ""
                : "・制作する歯に関する補足事項があればご記入ください。\n・納期希望日やセット希望日がございましたらご記入ください。"
            }
            disabled={isShowMode}
          />
        </Grid>
      </Grid>
      <Typography
        variant="h6"
        fontWeight="bold"
        gutterBottom
        sx={{ mt: "20px" }}
      >
        仕上げ方針
        <RequireLabel />
      </Typography>
      <Grid container spacing={2}>
        <Grid item xs={0.8} />
        <Grid item xs>
          <RadioButtonGroupInput
            source="finishing_type"
            label={false}
            row={false}
            sx={{ height: "200px" }}
            choices={FINISHING_TYPE_CHOICES}
            disabled={isShowMode}
            validate={required("必須です")}
          />
        </Grid>
      </Grid>
      <Typography
        variant="h6"
        fontWeight="bold"
        gutterBottom
        sx={{ mt: "20px" }}
      >
        インデックスのご希望
        <RequireLabel />
      </Typography>
      <Grid container spacing={2}>
        <Grid item xs={0.8} />
        <Grid item xs>
          {(!isShowMode && (
            <RadioButtonGroupInput
              source="method_type"
              label={false}
              row={false}
              choices={METHOD_TYPE_CHOICES}
              disabled={isShowMode}
              validate={required("必須です")}
            />
          )) ||
            (record && record.method_type && (
              <Typography color={"rgba(0, 0, 0, 0.38)"}>
                {METHOD_TYPE_MAP.get(record.method_type) ||
                  METHOD_TYPE_MAP_OLD.get(record.method_type)}
              </Typography>
            ))}
          {!isShowMode && (
            <>
              <Grid item xs={0.8} />
              <Grid item xs={10}>
                <Alert
                  variant="outlined"
                  severity="info"
                  sx={{
                    color: "#000000DE",
                    fontWeight: "400",
                    fontSize: "1rem",
                  }}
                >
                  ※症例によっては対応のご相談をさせていただく場合がございます。
                  <br></br>
                  ※ダイレクトブリッジや小窩裂溝の深い臼歯部咬合面修復は3Dプリント（ホルダータイプ）を推奨します。
                  <br></br>
                  ※べニアや隣接面を含む修復は、透明度と歯列密着度が高いシリコン（2層タイプ）を推奨します。
                  <br></br>
                  ※注入法は、インデックスを歯列に圧接してから、注入口からCRを注入する方法です。
                  <br></br>
                  &nbsp;&nbsp;CRの溢出が少なく、形態修正時間が短くなりますが、形状によっては気泡が発生しやすくなります。
                  <br></br>
                  &nbsp;&nbsp;（複雑な形状やブリッジの場合には、推奨いたしません。）
                  <br></br>
                  ※圧接法は、インデックス内部にCRを注入してから、歯列に圧接する方法です。
                  <br></br>
                  &nbsp;&nbsp;気泡の発生が少なくなりますが、溢出が発生し、形態修正に時間がかかる場合があります。
                  <br></br>
                </Alert>
              </Grid>
            </>
          )}
        </Grid>
      </Grid>
      <Typography
        variant="h6"
        fontWeight="bold"
        gutterBottom
        sx={{ mt: "20px" }}
      >
        添付zipファイル
      </Typography>
      <Grid container spacing={2}>
        <Grid item xs={0.8} />
        <Grid item xs={8}>
          {isShowMode ? (
            <FunctionField
              label={false}
              render={(record: any) => (
                <DelayedFileField {...record} resource="attachments" />
              )}
            />
          ) : (
            <>
              <FileInput
                accept="application/zip,application/x-zip-compressed"
                source="attachments"
                label={false}
                placeholder={
                  <div>
                    <p>
                      ここをクリック、もしくはファイルをドロップしてください
                    </p>
                    <p style={{ color: "red" }}>
                      zipファイルのみアップロード可能です
                    </p>
                  </div>
                }
                options={{
                  onDropRejected: () =>
                    alert("zipファイル以外はアップロードできません。"),
                }}
                sx={{
                  "& .RaFileInput-dropZone": {
                    borderRadius: "5px",
                    padding: "24px 10px",
                    backgroundColor: "#f0f0f0",
                    fontWeight: "600",
                  },
                }}
              >
                <FileField source="src" title="title" />
              </FileInput>
            </>
          )}
          {!isShowMode && (
            <>
              <Grid item xs={12}>
                <Alert
                  variant="outlined"
                  severity="info"
                  sx={{
                    color: "#000000DE",
                    fontWeight: "400",
                    fontSize: "1rem",
                    mb: "20px",
                  }}
                >
                  ※口腔内スキャンデータをアップロード前に、修復対象エリア（隣接歯含む）のデータに穴が生じていないことや歯間空隙のスキャン状況をご確認ください。
                  <br></br>
                  修復設計に必要なデータが不十分な場合、再撮影／印象採得、もしくは弊社で補正対応となります。補正対応の場合、インデックスの浮きやCRの溢出が発生し、チェアサイドで形態修正等に時間がかかる可能性があります。
                  <br></br>
                  <b>
                    ※アップロードできるファイルは150MBまでです。<br></br>
                    150MBを超える場合はファイルのアップロードをせず、上記にある「※制作する歯に関する補足事項（任意）」の欄に「ファイルサイズオーバー」とご記載ください。
                    担当者から別途、ご連絡を差し上げます。
                  </b>
                </Alert>
              </Grid>
            </>
          )}
        </Grid>
      </Grid>
      <Typography variant="h6" fontWeight="bold" gutterBottom>
        事前の修復形態の確認
        <RequireLabel />
      </Typography>
      <Grid container spacing={2}>
        <Grid item xs={0.8} />
        <Grid item xs={8}>
          <RadioButtonGroupInput
            source="need_pre_confirmation"
            row={false}
            label={false}
            validate={required("必須です")}
            choices={NEED_PRE_CONFIRMATION_CHOICES}
            disabled={isShowMode}
          />
          <FormDataConsumer<{
            index_delivery_date_no_need: any;
            index_delivery_date_need: any;
            design_delivery_date: string;
            need_pre_confirmation: string;
          }>>
            {({ formData, ...rest }) => {
              if (!formData.need_pre_confirmation) {
                return;
              }
              return formData.need_pre_confirmation ==
                NEED_PRE_CONFIRMATION_NO_NEED ? (
                <Grid item xs key={"no_need"}>
                  <Typography>インデックスの納品希望日（任意）</Typography>
                  <DateInput
                    source="index_delivery_date_no_need"
                    label={false}
                    disabled={isShowMode}
                  />
                  <AlertDeliveryDateMessage
                    targetDate={formData.index_delivery_date_no_need}
                    minDate={twoWeekLaterDate()}
                    isShowMode={isShowMode}
                  />
                </Grid>
              ) : (
                <Grid item xs key={"need"}>
                  <Typography>デザインの納品希望日（任意）</Typography>
                  <DateInput
                    source="design_delivery_date"
                    label={false}
                    disabled={isShowMode}
                  />
                  <AlertDeliveryDateMessage
                    targetDate={formData.design_delivery_date}
                    minDate={oneWeekLaterDate()}
                    isShowMode={isShowMode}
                  />
                  <Typography>インデックスの納品希望日（任意）</Typography>
                  <DateInput
                    source="index_delivery_date_need"
                    label={false}
                    disabled={isShowMode}
                  />
                  <AlertDeliveryDateMessage
                    targetDate={formData.index_delivery_date_need}
                    minDate={threeWeekLaterDate()}
                    isShowMode={isShowMode}
                  />
                </Grid>
              );
            }}
          </FormDataConsumer>
          {!isShowMode && (
            <>
              <Grid item xs>
                <Alert
                  variant="outlined"
                  severity="info"
                  sx={{
                    color: "#000000DE",
                    fontWeight: "400",
                    fontSize: "1rem",
                    mb: "20px",
                  }}
                >
                  ※18時以降の見積依頼は、翌日分として取り扱いをさせていただきます。
                  <br />
                  営業時間：平日9:00～18:00
                  <br />
                  休業日：土日祝、年末年始
                  <br />
                  <br />
                  ※納品日に関するその他のご相談は「制作する歯に関する補足事項」にご記入ください。
                </Alert>
              </Grid>
            </>
          )}
        </Grid>
      </Grid>
      <Typography variant="h6" fontWeight="bold" gutterBottom>
        スキャン時の歯肉圧排の有無
        <RequireLabel />
      </Typography>
      <Grid container spacing={2}>
        <Grid item xs={0.8} />
        <Grid item xs={8}>
          <RadioButtonGroupInput
            source="need_gum_retraction"
            row={false}
            label={false}
            validate={required("必須です")}
            choices={NEED_GUM_RETRACTION_CHOICES}
            disabled={isShowMode}
          />
          {!isShowMode && (
            <>
              <Grid item xs>
                <Alert
                  variant="outlined"
                  severity="info"
                  sx={{
                    color: "#000000DE",
                    fontWeight: "400",
                    fontSize: "1rem",
                    mb: "20px",
                  }}
                >
                  ※圧排ありの場合はマージンラインのインデックス精度が向上します。施術前にも再度圧排してください。
                </Alert>
              </Grid>
            </>
          )}
        </Grid>
      </Grid>
      <Typography variant="h6" fontWeight="bold" gutterBottom>
        本CR修復後の矯正歯科治療の予定
        <RequireLabel />
      </Typography>
      <Grid container spacing={2}>
        <Grid item xs={0.8} />
        <Grid item xs>
          <RadioButtonGroupInput
            source="orthodontic_treatment_plan"
            row={false}
            label={false}
            validate={required("必須です")}
            choices={ORTHODONTIC_TREATMENT_PLAN_CHOICES}
            disabled={isShowMode}
          />
        </Grid>
      </Grid>
      {!isShowMode && (
        <>
          <Typography variant="h6" fontWeight="bold" gutterBottom>
            以下ご確認の上、すべてのチェックボックスにチェックを入れてください。
            <RequireLabel />
          </Typography>
          <Grid container spacing={2}>
            <Grid item xs={0.8} />
            <Grid item xs>
              <CheckboxGroupInput
                source="final_confirmation"
                row={false}
                label={false}
                validate={required("必須です")}
                choices={[
                  { id: 1, name: "歯科医師の指示による発注である" },
                  {
                    id: 2,
                    name: "患者の医療個人情報を株式会社amidexに提供することについて患者の同意を得ている",
                  },
                  {
                    id: 3,
                    name: "Amidex™を活用した直接法コンポジットレジン修復治療であることを患者に説明し同意を得ている",
                  },
                  {
                    id: 4,
                    name: "「取引規約」を読み、内容を理解した上で同意する",
                  },
                ]}
              />
            </Grid>
          </Grid>
          <Grid container spacing={2}>
            <Grid item xs={0.8} />
            <Grid item xs={8}>
              <Card sx={{ height: "200px", overflowY: "scroll" }}>
                <Typography
                  variant="subtitle1"
                  fontWeight="bold"
                  sx={{ textAlign: "center" }}
                >
                  amidex取引規約
                </Typography>
                <Typography
                  variant="subtitle1"
                  sx={{ textAlign: "center" }}
                ></Typography>
                <Typography variant="subtitle1">
                  本取引規約（以下「本規約」と言います。）には、本件取引の条件および本件取引に関する当社と登録ユーザーの皆様との間の権利義務関係が定められています。
                  本件取引を行うに際しては、本規約の全文をお読みいただいたうえで、本規約に同意いただく必要があります。
                </Typography>
                {/* 第一条 */}
                <Typography variant="subtitle1" fontWeight="bold">
                  第1条（適用）
                </Typography>
                <Typography variant="subtitle1">
                  1.本規約は、本件取引の条件および本件取引に関する当社と登録ユーザーとの間の権利義務関係を定めることを目的とし、登録ユーザーと当社との間の本件取引の実施に関わる一切の関係に適用されます。
                </Typography>
                <Typography variant="subtitle1">
                  2.
                  当社が当社ウェブサイト上で掲載する本件取引の実施に関するルール
                  <br></br>
                  ・https://amidex.co.jp/wp-content/uploads/2024/03/Order_20240331_v1.0.pdf
                  <br></br>
                  ・http://amidex.co.jp/sitepolicy/
                </Typography>
                <Typography variant="subtitle1">
                  3.
                  本規約の内容と、前項のルールその他の本規約外における本件取引の説明等とが異なる場合は、本規約の規定が優先して適用されるものとします。
                </Typography>
                {/* 第二条 */}
                <Typography variant="subtitle1" fontWeight="bold">
                  第2条（定義）
                </Typography>
                <Typography variant="subtitle1">
                  本規約において使用する以下の用語は、各々以下に定める意味を有するものとします。
                </Typography>
                <Typography variant="subtitle1">
                  本規約において使用する以下の用語は、各々以下に定める意味を有するものとします。
                </Typography>
                <Typography variant="subtitle1">
                  （1）
                  「本サービス」とは、当社が提供するAmidex発注システムという名称の見積依頼システムに係るサービス（理由の如何を問わずサービスの名称または内容が変更された場合は、当該変更後のサービスを含みます。）を意味します
                </Typography>
                <Typography variant="subtitle1">
                  （2）
                  「本サービス利用規約」とは、本サービスの利用規約（amidex利用規約　http://amidex.co.jp/wp-content/uploads/2024/03/TermsofUse_20240328.pdf）を意味します。
                </Typography>
                <Typography variant="subtitle1">
                  （3）
                  「知的財産権」とは、著作権、特許権、実用新案権、意匠権、商標権その他の知的財産権（それらの権利を取得し、またはそれらの権利につき登録等を出願する権利を含みます。）を意味します。
                </Typography>
                <Typography variant="subtitle1">
                  （4）
                  「対象物品」とは、本サービスを用いた見積依頼（見積依頼に基づく発注を含みます。）の対象とされる物品（例えば、CR修復用インデックス、固定用ホルダーを含む場合がありますが、これに限りません。）を意味します。
                </Typography>
                <Typography variant="subtitle1">
                  （5）
                  「本件取引」とは、当社及び登録ユーザー間で行われる対象物品についての取引（例えば、売買、委託、請負を含む場合がありますが、これに限りません。）を意味します。
                </Typography>
                <Typography variant="subtitle1">
                  （6） 「当社」とは、株式会社amidexを意味します。
                </Typography>
                <Typography variant="subtitle1">
                  （7）
                  「当社ウェブサイト」とは、そのドメインが「amidex.co.jp」「amidex.net」である、当社が運営するウェブサイト（理由の如何を問わず、当社のウェブサイトのドメインまたは内容が変更された場合は、当該変更後のウェブサイトを含みます。）を意味します
                </Typography>
                <Typography variant="subtitle1">
                  （8）
                  「登録ユーザー」とは、本サービス利用規約に基づいて本サービスの利用者としての登録がなされた個人、団体または法人（歯科医院または医療法人を含みます。）を意味します。
                </Typography>
                <Typography variant="subtitle1">
                  （9）
                  「個別ユーザー」とは、登録ユーザーのために本サービスを利用する登録ユーザーの役員、社員、従業員その他関係者（登録ユーザーが医師登録した医師を含みます。）を意味します。
                </Typography>
                {/* 第三条 */}
                <Typography variant="subtitle1" fontWeight="bold">
                  第３条（本件取引に係る契約の締結時期）
                </Typography>
                <Typography variant="subtitle1">
                  登録ユーザーは、本サービスを利用することで、当社及び登録ユーザー間で対象物品についての見積依頼等を行うことができ、また、当社作成の最終見積書（登録ユーザーが本サービスを利用して当社に見積依頼した内容に基づき、当社が作成した本件取引についての最終見積書をいいます。）の内容に基づいて、対象物品についての本件取引に係る契約を行うことができます。
                  <br></br>
                  本件取引に係る契約は、登録ユーザーから当社に当社作成の最終見積書の内容で本件取引の申込みがなされ、当社がその申込みに承諾したときをもって締結されたものとします。
                </Typography>
                {/* 第四条 */}
                <Typography variant="subtitle1" fontWeight="bold">
                  第４条（引渡し）
                </Typography>
                <Typography variant="subtitle1">
                  1.
                  当社は、当社作成の最終見積書に別途記載したとおりに、対象物品を登録ユーザーに対して納入するものとします。
                </Typography>
                <Typography variant="subtitle1">
                  2.
                  登録ユーザーは、対象物品の納入を受けた場合、直ちに当該対象物品を検査して、品目違い又は数量不足等を発見したときは、納入後3日以内（以下「検査期間」という。）にその旨を登録ユーザーは当社に対して通知するものとし、検査期間満了日までに登録ユーザーから何らの通知もない場合は、検査期間満了日をもって対象物品の当社から登録ユーザーへの引渡しが完了するものとします。
                </Typography>
                {/* 第五条 */}
                <Typography variant="subtitle1" fontWeight="bold">
                  第５条（所有権の移転）
                </Typography>
                <Typography variant="subtitle1">
                  対象物品の所有権は、対象物品の引渡しが完了した時点をもって当社から登録ユーザーに移転するものとします。
                </Typography>
                {/* 第六条 */}
                <Typography variant="subtitle1" fontWeight="bold">
                  第６条（代金）
                </Typography>
                <Typography variant="subtitle1">
                  1.
                  登録ユーザーは、対象物品の代金について、当社作成の最終見積書に別途記載したとおりに、支払うものとします。尚、振込手数料は、登録ユーザーの負担とします。
                </Typography>
                <Typography variant="subtitle1">
                  2.
                  登録ユーザーが前項に定める金額の支払いを遅延したときは、当社は登録ユーザーに対して、払込期日の翌日から全額の支払いが完了する日まで、年率１４．５%の割合による遅延損害金を請求できるものとする
                </Typography>
                {/* 第七条 */}
                <Typography variant="subtitle1" fontWeight="bold">
                  第７条（危険負担）
                </Typography>
                <Typography variant="subtitle1">
                  対象物品の納入日より前に対象物品の滅失又は毀損が生じた場合、登録ユーザーの責めに帰すべき事由による場合を除き当社が危険を負担するものとし、納入日後に生じた滅失又は毀損については、当社の責めに帰すべき事由による場合を除き登録ユーザーが危険を負担するものとします。
                </Typography>
                {/* 第八条 */}
                <Typography variant="subtitle1" fontWeight="bold">
                  第８条（解除等）
                </Typography>
                <Typography variant="subtitle1">
                  1.
                  当社は、登録ユーザーが、以下の各号のいずれかの事由に該当する場合は、事前に通知または催告することなく、本件取引の実施を一時的に停止し、または本件取引を解除することができます。登録ユーザーが危険を負担するものとします。
                </Typography>
                <Typography variant="subtitle1">
                  （1）
                  本規約、本サービス利用規約または本取引に係る契約のいずれかの条項に違反した場合
                </Typography>
                <Typography variant="subtitle1">
                  （2）
                  本サービスに対する登録事項および見積依頼の内容に虚偽の事実があることが判明した場合
                </Typography>
                <Typography variant="subtitle1">
                  （3）
                  支払停止もしくは支払不能となり、または破産手続開始、民事再生手続開始、会社更生手続開始、特別清算開始もしくはこれらに類する手続の開始の申立てがあった場合
                </Typography>
                <Typography variant="subtitle1">
                  （4）
                  当社からの問い合わせその他の回答を求める連絡に対して30日間以上応答がない場合
                </Typography>
                <Typography variant="subtitle1">
                  2.
                  前項各号のいずれかの事由に該当した場合、登録ユーザーは、当社に対して負っている債務の一切について当然に期限の利益を失い、直ちに当社に対して全ての債務の支払を行わなければなりません。
                </Typography>
                {/* 第九条 */}
                <Typography variant="subtitle1" fontWeight="bold">
                  第9条（保証の否認および免責）
                </Typography>
                <Typography variant="subtitle1">
                  1.
                  当社は、当社作成の最終見積書に本規約以外の記載として別途記載した事項を除き、対象物品および本件取引が登録ユーザーの特定の目的に適合すること、対象物品および本件取引が期待する機能、商品的価値、正確性および有用性を有すること、対象物品の提供および本件取引の実施が登録ユーザーに適用のある法令または業界団体の内部規則等に適合すること、対象物品の提供および本件取引の実施が第三者の知的財産権、肖像権、プライバシーの権利、名誉、その他の権利または利益を侵害しないこと、対象物品および本サービスを継続的に利用できること、ならびに対象物品および本サービスに不具合が生じないことについて、何ら保証するものではありません。
                </Typography>
                <Typography variant="subtitle1">
                  2.
                  当社は、当社作成の最終見積書に本規約以外の記載として別途記載した事項以外は、当社に故意または重過失がある場合を除き、本件取引に関して登録ユーザーが被った損害につき、当該損害の発生原因となった本件取引または対象物品の取引額を超えて、賠償する責任を負わないものとします。
                </Typography>
                <Typography variant="subtitle1">
                  3.
                  本サービスまたは当社ウェブサイトに関連して登録ユーザーと他の登録ユーザーまたは第三者との間において生じた取引、連絡、紛争等については、登録ユーザーが自己の責任によって解決するものとします。
                </Typography>
                {/* 第十条 */}
                <Typography variant="subtitle1" fontWeight="bold">
                  第10条（秘密保持）
                </Typography>
                <Typography variant="subtitle1">
                  登録ユーザーは、本件取引に関連して当社が登録ユーザーに対して秘密に取扱うことを求めて開示した非公知の情報について、当社の事前の書面による承諾がある場合を除き、秘密に取扱うものとします。
                </Typography>
                {/* 第十一条 */}
                <Typography variant="subtitle1" fontWeight="bold">
                  第11条（本件取引上の地位の譲渡等）
                </Typography>
                <Typography variant="subtitle1">
                  1.
                  登録ユーザーは、当社の書面による事前の承諾なく、本件取引上の地位または本規約もしくは本件取引に基づく権利もしくは義務につき、第三者に対し、譲渡、移転、担保設定、その他の処分をすることはできません。
                </Typography>
                <Typography variant="subtitle1">
                  2.
                  当社は本件取引に係る事業を他者に譲渡した場合には、当該事業譲渡に伴い本件取引上の地位または本規約もしくは本件取引に基づく権利および義務ならびに登録ユーザーの登録事項その他の顧客情報を当該事業譲渡の譲受人に譲渡することができるものとし、登録ユーザーは、かかる譲渡につき本項において予め同意したものとします。なお、本項に定める事業譲渡には、通常の事業譲渡のみならず、会社分割その他事業が移転するあらゆる場合を含むものとします
                </Typography>
                {/* 第十二条 */}
                <Typography variant="subtitle1" fontWeight="bold">
                  第12条（分離可能性）
                </Typography>
                <Typography variant="subtitle1">
                  本規約のいずれかの条項またはその一部が、法令等により無効または執行不能と判断された場合であっても、本規約の残りの規定および一部が無効または執行不能と判断された規定の残りの部分は、継続して完全に効力を有するものとします。
                </Typography>
                {/* 第十三条 */}
                <Typography variant="subtitle1" fontWeight="bold">
                  第13条（準拠法および裁判管轄）
                </Typography>
                <Typography variant="subtitle1">
                  1. 本規約および本件取引に係る契約の準拠法は日本法とします。
                </Typography>
                <Typography variant="subtitle1">
                  2.
                  本規約または本件取引に係る契約に起因し、またはこれらに関連する一切の紛争については、東京地方裁判所を第一審の専属的合意管轄裁判所とします。
                </Typography>
                <Typography variant="subtitle1">2024年4月1日制定</Typography>
              </Card>
            </Grid>
          </Grid>
        </>
      )}
    </>
  );
};

const validatePatientKana = [
  required("患者名（カナ）を入力してください"),
  regex(/^[ァ-ンヴー]*$/, "全角カナで入力してください"),
];

const validateForm = (values: any) => {
  const errors = {};

  // 4つのグループのいずれかにチェックが入っているか確認
  const isChecked = [
    "quadrant_1",
    "quadrant_2",
    "quadrant_3",
    "quadrant_4",
  ].some((quadrant) => values[quadrant] && values[quadrant].length > 0);

  if (!isChecked) {
    errors.quadrant_4 = "少なくとも一つの項目を選択してください。";
  }

  return errors;
};
