import { todo } from "node:test";
import { AuthApi, Configuration, UsersApi } from "../generated";

const readApiBaseFromEnv = (): string => {
  // Get API base URL from env
  // Priority is given to same host in the browser when environemnt is production
  // todo 暫定処置
  if (
    process.env.NODE_ENV === "production" &&
    !document.location.host.startsWith("localhost")
  ) {
    // 本番はポートで指定しない
    return "https://back.amidex.net";
    //test
    // return "http://153.126.204.154:8000";
  } else {
    // 本番
    return "http://153.126.188.61:8000";
    //test
    // return "http://153.126.204.154:8000";
    // return "http://localhost:8000";
  }
};

const readAccessToken = async (): Promise<string> => {
  return localStorage.getItem("token") || "";
};

export const basePath: string = readApiBaseFromEnv();

const apiConfig: Configuration = new Configuration({
  basePath,
  accessToken: readAccessToken,
});

export const authApi: AuthApi = new AuthApi(apiConfig);
export const userApi: UsersApi = new UsersApi(apiConfig);
