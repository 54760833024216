import { useController } from "react-hook-form";
import React, { useEffect, useRef, useState } from "react";

const DentalMap = ({ src, mapName, isShowMode }) => {
  const scriptLoaded = useRef(false);
  const resizeTimer = useRef(null);
  const [selectedCoords, setSelectedCoords] = useState([]);
  const [windowWidth, setWindowWidth] = useState([0]);
  const [windowHeight, setWindowHeight] = useState([0]);

  useEffect(() => {
    const resize = () => {
      clearTimeout(resizeTimer.current);
      resizeTimer.current = setTimeout(() => {
        setWindowWidth(window.innerWidth);
        setWindowHeight(window.innerHeight);
      }, 260);
    };

    if (!scriptLoaded.current) {
      const script = document.createElement("script");
      script.src =
        "https://cdnjs.cloudflare.com/ajax/libs/image-map-resizer/1.0.10/js/imageMapResizer.min.js";
      script.onload = () => {
        window.imageMapResize();
        scriptLoaded.current = true;
        setWindowWidth(window.innerWidth);
        setWindowHeight(window.innerHeight);
      };

      window.addEventListener("resize", resize);
      document.body.appendChild(script);
    }

    return () => {
      if (resizeTimer.current) {
        clearTimeout(resizeTimer.current);
      }
    };
  }, []);

  const quadrants: any = {};
  quadrants["quadrant1"] = useController({
    name: "quadrant_1",
    defaultValue: "",
  });
  quadrants["quadrant2"] = useController({
    name: "quadrant_2",
    defaultValue: "",
  });
  quadrants["quadrant3"] = useController({
    name: "quadrant_3",
    defaultValue: "",
  });
  quadrants["quadrant4"] = useController({
    name: "quadrant_4",
    defaultValue: "",
  });

  useEffect(() => {
    paintSelectedArea();
  }, [
    quadrants["quadrant1"].field.value,
    quadrants["quadrant2"].field.value,
    quadrants["quadrant3"].field.value,
    quadrants["quadrant4"].field.value,
    windowWidth,
    windowHeight,
  ]);

  const paintSelectedArea = () => {
    let newCoords = [];
    [...document.getElementsByClassName("dentalChild")].map((dentalChild) => {
      Object.keys(quadrants).some((key) => {
        if (key !== "quadrant" + dentalChild.dataset.quadrant) {
          //現在のポリゴンのquadrantとチェック済みの結果が入っているquadrantsのquadrantが一致していないものは処理しない
          return false; //continueのこと
        }
        if (
          quadrants[key].field.value.includes(
            parseInt(dentalChild.dataset.number)
          )
        ) {
          newCoords = [...newCoords, dentalChild.coords];
        }
      });
    });

    setSelectedCoords(newCoords);
  };

  const handleAreaClick = (event, quadrantArea: number, number: number) => {
    const coords = event.target.coords;
    const quadrantX = quadrants["quadrant" + quadrantArea];
    const currentValue = quadrantX.field.value || [];
    let newValue = [],
      newCoords = [];

    if (currentValue.includes(number)) {
      newValue = currentValue.filter((v) => v !== number);
    } else {
      newValue = [...currentValue, number];
    }

    quadrantX.field.onChange(newValue);
  };

  return (
    <div style={{ position: "relative" }}>
      <img
        src={src}
        useMap={`#${mapName}`}
        alt="Dental Map"
        style={{ width: "100%", height: "auto" }}
      />
      <svg
        style={{
          position: "absolute",
          top: 0,
          left: 0,
          width: "100%",
          height: "100%",
        }}
        pointerEvents="none"
      >
        {selectedCoords.map((coord) => (
          <polygon points={coord} fill="rgba(3, 169, 244,0.5)" />
        ))}
      </svg>
      <map name="ImageMap" id="dentalMap">
        <area
          class="dentalChild"
          name="dentalChild"
          shape="poly"
          coords="58,283,58,284,53,288,50,294,50,303,52,313,56,321,61,326,70,327,64,328,71,327,74,327,79,324,81,320,84,314,85,305,85,297,82,287,76,285,72,284,71,282,58,282,58,284,58,284"
          alt=""
        />
        <area
          class="dentalChild"
          name="dentalChild"
          shape="poly"
          coords="59,228,59,228,59,228,55,230,54,236,50,244,50,254,51,266,56,273,60,275,70,276,77,273,85,268,88,256,88,246,73,229,64,227,64,227"
          alt=""
          onClick={(e) => !isShowMode && handleAreaClick(e, 1, 7)}
          data-quadrant="1"
          data-number="7"
        />
        <area
          class="dentalChild"
          name="dentalChild"
          shape="poly"
          coords="84,178,76,177,71,180,67,188,63,200,62,214,68,224,74,228,86,229,90,227,94,220,97,206,97,196,92,184,89,180,89,180"
          alt=""
          onClick={(e) => !isShowMode && handleAreaClick(e, 1, 6)}
          data-quadrant="1"
          data-number="6"
        />
        <area
          class="dentalChild"
          shape="poly"
          coords="94,144,88,150,84,160,83,165,86,172,90,176,95,178,100,180,105,178,110,173,111,166,105,154,98,145,98,145,95,144,95,144"
          alt=""
          onClick={(e) => !isShowMode && handleAreaClick(e, 1, 5)}
          data-quadrant="1"
          data-number="5"
        />
        <area
          class="dentalChild"
          shape="poly"
          coords="108,114,104,118,100,124,98,128,100,141,107,149,117,154,120,153,124,148,124,141,119,127,118,123,114,117,113,116,113,116"
          alt=""
          onClick={(e) => !isShowMode && handleAreaClick(e, 1, 4)}
          data-quadrant="1"
          data-number="4"
        />
        <area
          class="dentalChild"
          shape="poly"
          coords="135,77,127,82,121,88,118,94,115,100,115,111,119,119,124,125,130,129,138,131,142,129,145,123,145,110,144,95,142,84,138,78,133,77,134,79"
          alt=""
          onClick={(e) => !isShowMode && handleAreaClick(e, 1, 3)}
          data-quadrant="1"
          data-number="3"
        />
        <area
          class="dentalChild"
          shape="poly"
          coords="168,54,162,54,156,57,150,62,146,66,144,69,143,71,143,78,145,82,149,86,157,93,161,96,165,96,168,97,171,94,172,90,173,83,174,73,173,63,172,60,172,60"
          alt=""
          onClick={(e) => !isShowMode && handleAreaClick(e, 1, 2)}
          data-quadrant="1"
          data-number="2"
        />
        <area
          class="dentalChild"
          shape="poly"
          coords="213,59,213,48,211,43,207,40,201,39,190,43,183,47,178,51,176,57,176,63,178,70,185,85,190,92,196,93,203,92,207,86,211,76,211,76"
          alt=""
          onClick={(e) => !isShowMode && handleAreaClick(e, 1, 1)}
          data-quadrant="1"
          data-number="1"
        />
        <area
          class="dentalChild"
          shape="poly"
          coords="224,39,219,42,217,46,217,55,218,65,221,80,226,90,233,92,238,90,243,87,251,69,254,58,249,47,231,38,224,38,223,37"
          alt=""
          onClick={(e) => !isShowMode && handleAreaClick(e, 2, 1)}
          data-quadrant="2"
          data-number="1"
        />
        <area
          class="dentalChild"
          shape="poly"
          coords="263,54,270,55,281,62,285,68,288,75,286,78,283,84,268,94,262,95,259,93,255,83,257,59,257,59"
          alt=""
          onClick={(e) => !isShowMode && handleAreaClick(e, 2, 2)}
          data-quadrant="2"
          data-number="2"
        />
        <area
          class="dentalChild"
          shape="poly"
          coords="295,76,291,79,288,89,286,103,286,118,288,125,292,131,298,130,310,121,316,112,316,100,313,91,306,83,300,78,295,76,295,76"
          alt=""
          onClick={(e) => !isShowMode && handleAreaClick(e, 2, 3)}
          data-quadrant="2"
          data-number="3"
        />
        <area
          class="dentalChild"
          shape="poly"
          coords="324,115,318,116,314,123,309,135,308,145,310,152,315,153,319,152,326,147,330,142,332,136,332,128,332,128"
          alt=""
          onClick={(e) => !isShowMode && handleAreaClick(e, 2, 4)}
          data-quadrant="2"
          data-number="4"
        />
        <area
          class="dentalChild"
          shape="poly"
          coords="340,147,336,143,331,145,323,156,321,164,320,172,324,179,331,180,337,178,343,174,346,167,347,160,347,160"
          alt=""
          onClick={(e) => !isShowMode && handleAreaClick(e, 2, 5)}
          data-quadrant="2"
          data-number="5"
        />
        <area
          class="dentalChild"
          shape="poly"
          coords="354,176,346,178,342,180,339,183,333,195,332,207,335,214,337,225,340,228,355,227,362,223,366,214,366,197,361,184,358,178,358,178"
          alt=""
          onClick={(e) => !isShowMode && handleAreaClick(e, 2, 6)}
          data-quadrant="2"
          data-number="6"
        />
        <area
          class="dentalChild"
          shape="poly"
          coords="372,229,364,229,360,229,351,237,343,249,344,262,346,269,355,275,365,276,372,276,378,271,381,261,380,248,377,238,375,231,375,231"
          alt=""
          onClick={(e) => !isShowMode && handleAreaClick(e, 2, 7)}
          data-quadrant="2"
          data-number="7"
        />
        <area
          class="dentalChild"
          shape="poly"
          coords="365,282,359,282,351,284,346,293,346,309,350,320,356,325,368,324,375,319,379,308,380,294,378,287,371,281,371,281"
          alt=""
        />
        <area
          class="dentalChild"
          shape="poly"
          coords="75,381,68,384,61,388,57,395,53,409,54,421,57,429,64,432,73,433,78,431,82,428,88,413,88,396,81,385,76,384,74,383"
          alt=""
        />
        <area
          class="dentalChild"
          shape="poly"
          coords="85,433,76,436,68,443,64,451,64,461,64,471,67,479,71,486,83,486,90,481,100,470,102,462,103,449,94,435,94,435"
          alt=""
          onClick={(e) => !isShowMode && handleAreaClick(e, 3, 7)}
          data-quadrant="3"
          data-number="7"
        />
        <area
          class="dentalChild"
          shape="poly"
          coords="102,487,93,486,81,492,78,501,77,519,80,531,86,538,94,541,99,538,105,531,111,517,112,501,108,490,102,487,102,487"
          alt=""
          onClick={(e) => !isShowMode && handleAreaClick(e, 3, 6)}
          data-quadrant="3"
          data-number="6"
        />
        <area
          class="dentalChild"
          shape="poly"
          coords="130,533,119,530,112,532,105,538,102,547,102,555,110,568,115,571,122,569,128,560,134,546,134,546"
          alt=""
          onClick={(e) => !isShowMode && handleAreaClick(e, 3, 5)}
          data-quadrant="3"
          data-number="5"
        />
        <area
          class="dentalChild"
          shape="poly"
          coords="144,562,137,562,126,570,122,578,123,585,130,594,137,597,142,594,144,591,147,582,148,566,148,566"
          alt=""
          onClick={(e) => !isShowMode && handleAreaClick(e, 3, 4)}
          data-quadrant="3"
          data-number="4"
        />
        <area
          class="dentalChild"
          shape="poly"
          coords="163,580,154,585,146,592,142,599,143,604,151,612,155,614,159,612,163,607,167,592,165,581,165,582"
          alt=""
          onClick={(e) => !isShowMode && handleAreaClick(e, 3, 3)}
          data-quadrant="3"
          data-number="3"
        />
        <area
          class="dentalChild"
          shape="poly"
          coords="190,598,185,594,178,594,171,600,164,613,163,623,166,627,182,631,186,625,190,609,190,609"
          alt=""
          data-value="quadrant_3_2"
          onClick={(e) => !isShowMode && handleAreaClick(e, 3, 2)}
          data-quadrant="3"
          data-number="2"
        />
        <area
          class="dentalChild"
          shape="poly"
          coords="206,598,199,602,194,610,192,623,192,634,197,638,208,639,212,636,214,630,213,614,208,601,208,601"
          alt=""
          onClick={(e) => !isShowMode && handleAreaClick(e, 3, 1)}
          data-quadrant="3"
          data-number="1"
        />
        <area
          class="dentalChild"
          shape="poly"
          coords="226,597,220,603,218,613,218,622,217,630,219,636,222,639,231,640,236,638,239,635,239,618,236,608,232,601,232,601"
          alt=""
          onClick={(e) => !isShowMode && handleAreaClick(e, 4, 1)}
          data-quadrant="4"
          data-number="1"
        />
        <area
          class="dentalChild"
          shape="poly"
          coords="249,593,242,594,239,597,240,606,241,614,244,625,246,631,254,630,261,627,265,625,266,618,264,610,258,599,252,594,252,594"
          alt=""
          onClick={(e) => !isShowMode && handleAreaClick(e, 4, 2)}
          data-quadrant="4"
          data-number="2"
        />
        <area
          class="dentalChild"
          shape="poly"
          coords="267,581,265,584,265,597,266,604,269,611,274,614,279,613,287,604,287,597,283,591,274,583,274,583"
          alt=""
          onClick={(e) => !isShowMode && handleAreaClick(e, 4, 3)}
          data-quadrant="4"
          data-number="3"
        />
        <area
          class="dentalChild"
          shape="poly"
          coords="290,562,286,565,284,569,285,578,287,588,290,593,296,598,302,595,307,589,310,585,308,573,302,567,295,563,295,563"
          alt=""
          onClick={(e) => !isShowMode && handleAreaClick(e, 4, 4)}
          data-quadrant="4"
          data-number="4"
        />
        <area
          class="dentalChild"
          shape="poly"
          coords="309,532,302,535,298,541,297,547,299,554,302,562,308,570,317,574,322,568,328,558,330,551,327,544,322,536,322,536"
          alt=""
          onClick={(e) => !isShowMode && handleAreaClick(e, 4, 5)}
          data-quadrant="4"
          data-number="5"
        />
        <area
          class="dentalChild"
          shape="poly"
          coords="337,488,329,487,322,490,319,496,317,506,320,518,324,532,330,539,337,540,343,539,347,534,351,525,352,511,348,494,349,494"
          alt=""
          onClick={(e) => !isShowMode && handleAreaClick(e, 4, 6)}
          data-quadrant="4"
          data-number="6"
        />
        <area
          class="dentalChild"
          shape="poly"
          coords="344,436,338,438,334,442,330,449,328,457,329,463,331,471,341,484,347,488,356,488,359,487,362,482,365,475,366,465,365,450,359,441,359,441"
          alt=""
          onClick={(e) => !isShowMode && handleAreaClick(e, 4, 7)}
          data-quadrant="4"
          data-number="7"
        />
        <area
          class="dentalChild"
          shape="poly"
          coords="359,383,350,383,345,388,342,397,341,410,343,418,347,427,352,431,359,431,366,432,371,428,375,421,376,408,371,392,366,386,366,386"
          alt=""
        />
      </map>
    </div>
  );
};

export default DentalMap;
