import { UserIdentity } from "react-admin";
import { authApi, userApi } from "./env";

type loginFormType = {
  email: string;
  password: string;
};

const authProvider = {
  login: async ({ email, password }: loginFormType) => {
    const formData = { username: email, password };
    const resp = await authApi.authJwtLogin(formData);
    localStorage.setItem("token", resp.data.access_token);
    return Promise.resolve();
  },
  logout: () => {
    localStorage.removeItem("token");
    localStorage.removeItem("permissions");
    return Promise.resolve();
  },
  checkError: (error: { status: number }) => {
    const status = error.status;
    if (status === 401 || status === 403) {
      localStorage.removeItem("token");
      localStorage.removeItem("permissions");
      return Promise.reject();
    }
    return Promise.resolve();
  },
  checkAuth: (a: any) => {
    return localStorage.getItem("token") ? Promise.resolve() : Promise.reject();
  },

  // loginのタイミングでgetIdentityよりもgetPermissionsが先に来てしまうのでlocalstorageのpermissionsを見ると権限判定が誤るのでやめる。
  // 例：初回ログインの際にpermissionsがundefinedになる。権限を変えてログインすると前回のpermissionsを見てしまう。
  // getIdentityとgetPermissionsが呼ばれたらそれぞれ毎回permissionsを再取得して更新する。これにより画面更新でも毎回permissionsの再取得もされる。
  getPermissions: async () => {
    const beforeLogin = !localStorage.getItem("token");
    if (beforeLogin) {
      // tokenがなければログイン前なのでpermissionsは空のオブジェクトで返す ※ログイン前にusersCurrentUser()を呼ぶと読み込み中になってしまう
      return Promise.resolve({});
    }
    const resp = await userApi.usersCurrentUser();
    localStorage.setItem("permissions", JSON.stringify(resp.data));
    return resp.data;
  },
  getIdentity: async (): Promise<UserIdentity> => {
    const resp = await userApi.usersCurrentUser();
    localStorage.setItem("permissions", JSON.stringify(resp.data));
    return resp.data as UserIdentity;
  },
};

export default authProvider;
