import {
  UserMenu,
  MenuItemLink,
  AppBar,
  Layout,
  Logout,
  TitlePortal,
} from "react-admin";
import { ProfileProvider } from "../pages/ProfileEdit";
import SettingsIcon from "@mui/icons-material/Settings";
import { Typography, Box } from "@mui/material";

const MyUserMenu = (props: any) => {
  return (
    <UserMenu {...props}>
      <MenuItemLink
        to="/my-profile"
        primaryText="My Profile"
        leftIcon={<SettingsIcon />}
      />
      <Logout key="logout" />
    </UserMenu>
  );
};

const CustomAppBar = (props: any) => (
  <AppBar
    {...props}
    display="flex"
    justifyContents="between"
    style={{ background: "#1e2e50" }}
    userMenu={<MyUserMenu />}
  >
    <Box
      display="flex"
      alignItems="center"
      style={{ width: "90%", background: "white", padding: "2px 8px" }}
    >
      <img src="/header_logo.jpg" alt="Logo" style={{ height: "50px" }} />
      <Typography
        style={{ color: "#1e2e50", margin: "20px 0 0 20px", fontWeight: "600" }}
        id="react-admin-title"
      ></Typography>
    </Box>
  </AppBar>
);

export default CustomAppBar;
