import { FormEvent, ReactElement } from "react";

import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import CardActions from "@mui/material/CardActions";
import TextField from "@mui/material/TextField";
import { Notification } from "react-admin";
import authStyles from "./authStyles";
import LockIcon from "@mui/icons-material/Lock";

interface AuthProps {
  submit(event: FormEvent): void;
  setEmail(value: string): void;
  setPassword?(value: string): void;
  actionName: string;
  extraActions?: ReactElement;
}

const Auth: React.FC<AuthProps> = ({
  submit,
  setEmail,
  setPassword,
  actionName,
  extraActions,
}) => {
  return (
    <form noValidate onSubmit={submit}>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
          backgroundColor: "#fff",
        }}
      >
        <img src="/login_logo.png" alt="" />
        <h3
          style={{ marginTop: "-40px", marginBottom: "80px", color: "#1e2e50" }}
        >
          amidex 発注見積もり依頼システム
        </h3>
      </div>
      <div style={authStyles.main}>
        <div style={authStyles.form}>
          <div>
            <label htmlFor="" style={authStyles.label}>
              メールアドレス
            </label>
            <TextField
              id="email"
              label="Email"
              type="email"
              onChange={(e) => setEmail(e.target.value)}
              fullWidth
            />
          </div>

          {setPassword && (
            <div>
              <label htmlFor="" style={authStyles.label}>
                パスワード
              </label>
              <TextField
                id="password"
                label="Password"
                type="password"
                fullWidth
                onChange={(e) => setPassword(e.target.value)}
              />
            </div>
          )}
          <CardActions
            style={{ textAlign: "left", width: "100%", padding: "0" }}
          >
            <Button
              variant="contained"
              type="submit"
              fullWidth
              style={{
                backgroundColor: "#1e2e50",
                width: "100%",
                margin: "20px 0",
              }}
            >
              {actionName}
            </Button>
          </CardActions>
          <CardActions sx={authStyles.actions}>{extraActions}</CardActions>

          <Notification />
        </div>
      </div>
    </form>
  );
};

export default Auth;
