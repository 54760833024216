import { useGetOne, FileField } from "react-admin";
import { CircularProgress, Typography } from "@mui/material";

export const DelayedFileField = (props: any) => {
  const { data: attachments_by_order_id, isLoading } = useGetOne(
    props.resource,
    { id: props.id }
  );

  const hasAttachment =
    attachments_by_order_id &&
    attachments_by_order_id.attachments &&
    attachments_by_order_id.attachments.length > 0;

  return (
    <>
      {isLoading ? (
        <CircularProgress
          size={35}
          sx={{
            mt: 1,
          }}
        />
      ) : hasAttachment ? (
        <FileField
          record={attachments_by_order_id}
          source="attachments"
          src="src"
          title="title"
          download={attachments_by_order_id.attachments[0].title}
          sx={{
            paddingLeft: "20px",
          }}
        />
      ) : (
        <Typography variant="subtitle1">{"なし"}</Typography>
      )}
    </>
  );
};
