export const RequireLabel = (props: any) => (
  <span
    style={{
      padding: "4px 8px",
      backgroundColor: "#FF4500",
      borderRadius: "8px",
      fontWeight: "bold",
      marginLeft: "8px",
      fontSize: "16px",
    }}
  >
    必須
  </span>
);
